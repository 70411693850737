import { useContext, useEffect, useState } from "react";

import { useNavigate, Navigate, useParams } from "react-router-dom";

import { RequestLeadDataContext } from "../../../context/requestLeadsdataContext";

export const RequestLeadsPrivateLoginRoute = ({ children }) => {
  let { paymentJoshLogin } = useContext(RequestLeadDataContext);
  if (paymentJoshLogin) {
    return <Navigate to={`/request-leads`} />;
  }
  return children;
};
