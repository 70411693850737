import { useContext, useEffect, useState } from "react";

import { useNavigate, Navigate, useParams } from "react-router-dom";

import { DataContext } from "../context/dataContext";

export const FinanceRoute = ({ children }) => {
  const { id } = useParams();
  let { loginRequired } = useContext(DataContext);
  let [login, setLogin] = useState(
    localStorage.getItem(`formId${id}_login_document_form`)
  );
  let [token, setToken] = useState(
    localStorage.getItem(`formId${id}_token_document_form`)
  );
  if ((login == "done" && token != undefined) || loginRequired == false) {
    return <Navigate to={`/scholarship/upsc/document-form/${id}`} />;
  }
  return children;
};
