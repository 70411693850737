import { useContext, useEffect, useState } from "react";

import { useNavigate, Navigate, useParams } from "react-router-dom";
import { SecondDataContext } from "../../context/secondDataContext";

export const LoginCounsellorRoute = ({ children }) => {

  let [CounsellorData, setCounsellorData] = useState(
    JSON.parse(localStorage.getItem("CounsellorData"))
  );
  if (
    CounsellorData !== undefined && CounsellorData !== null && CounsellorData.token
    
  ) {
    return <Navigate to={`/finance/dashboard`} />;
  }
  return children;
};
