import { createContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const RequestLeadDataContext = createContext({
 paymentJoshLogin: false,

  handleLoginSuccess: () => {},

});
export const RequestLeadDataContextProvider = ({ children }) => {
  let [paymentJoshLogin, setPaymentJoshLogin] = useState(false);

  let handleLoginSuccess = () => {
    setPaymentJoshLogin(true);
  };
  return (
    <RequestLeadDataContext.Provider
      value={{
        paymentJoshLogin,
        handleLoginSuccess,
      }}
    >
      {children}
    </RequestLeadDataContext.Provider>
  );
};
