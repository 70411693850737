import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { PrivateLoginRoute } from "./components/loginRoute";
import CloseIcon from "@mui/icons-material/Close";

import { GoogleOAuthProvider } from "@react-oauth/google";
import Cookies from "js-cookie";
import { FinanceRoute } from "./components/FinanceRoute";
import { LoginCounsellorRoute } from "./components/Routes/loginCounsellorRoute";
import { PrivateCounsellorRoute } from "./components/Routes/privateCounsellorRoute";
import { RequestLeadsPrivateLoginRoute } from "./components/RequestLead/Routes/requestloginPrivateRoute";



const JeeHome = React.lazy(() => import("./components/StaticForms/JEE/JeeForm"));
const NeetHome = React.lazy(() =>
  import("./components/StaticForms/NEET/NeetForm")
);
const JrfHome = React.lazy(() =>
  import("./components/StaticForms/JRF/JrfForm")
); ;





const OptimisedFormHome = React.lazy(() => import("./components/OptimisedForm"));

const FinanceForm = React.lazy(() => import("./components/FinanceForm"));

const FeedbackHome = React.lazy(() => import("./components/FeedbackForm")); ;
const BasicFinanceModal = React.lazy(() =>
  import("./components/NewFinanceForm/GoogleFinancePopUp")
);

const CounsellorSignIn = React.lazy(() =>
  import("./components/CounsellorForm/Login/Login")
);

const CounsellorDashboard = React.lazy(() =>
  import("./components/CounsellorForm/Dashboard/CounsellorHome")
);
const FormDetails = React.lazy(() =>
  import("./components/CounsellorForm/FormDetails/FormDetails")
);

const CounsellorNewNavbar = React.lazy(() =>
  import("./components/CounsellorForm/Navbar/CounsellorNavbar")
);

const PaymentPage = React.lazy(() =>
  import("./components/RequestLead/PaymentPage/PaymentPage"))
const BasicLeadRequestModal=  React.lazy(() =>
  import("./components/RequestLead/PaymentPage/PaymentLogin"))


const Test = React.lazy(() => import("./components/DynamicForm/Test"));
const BasicModal = React.lazy(() =>
  import("./components/FormComponents/NameComponent/GooglePopUp")
);
const Home = React.lazy(() => import("./components/Home"));
const UPSCHome = React.lazy(() => import("./components/UPSCHome"));
function App() {
  let navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  // State for managing modal visibility
  const [showModal, setShowModal] = useState(!Cookies.get("termsAccepted"));

  // Function to handle the acceptance of terms
  const handleAcceptTerms = () => {
    Cookies.set("termsAccepted", true, { expires: 365 });
    setShowModal(false);
  };

  return (
    <div>
      {pathname === "/finance/dashboard" ||
      pathname === "/finance/login" ||
      pathname.startsWith("/finance/dashboard") ? (
        <CounsellorNewNavbar />
      ) : null}

      <Routes>
        <Route
          path={`/scholarship/upsc/submit-form/:id`}
          element={<UPSCHome />}
        />
        <Route
          path={`/scholarship/jee/submit-form/v2/:id`}
          element={<JeeHome />}
        />
        <Route
          path={`/scholarship/neet/submit-form/v2/:id`}
          element={<NeetHome />}
        />
        <Route
          path={`/scholarship/jfw/submit-form/v2/:id`}
          element={<JrfHome />}
        />
        <Route
          path={`/scholarship/upsc/feedback-form/:id`}
          element={<FeedbackHome />}
        />
        <Route
          path={`/scholarship/upsc/submit-form/v2/:id`}
          element={<OptimisedFormHome />}
        />
        <Route
          path={`/scholarship/upsc/submit-form/v1/:id`}
          element={<Test />}
        />
        {/* <Route
          path={`/scholarship/upsc/finance-form/:id`}
          element={
            <GoogleOAuthProvider clientId="555163836458-ekq299o1li21bvqavnppmuqjt66vv95o.apps.googleusercontent.com">
              <Home />
            </GoogleOAuthProvider>
          }
        />
        <Route
          path={`/scholarship/upsc/finance-form/:id/login`}
          element={
            <PrivateLoginRoute>
              <GoogleOAuthProvider clientId="555163836458-ekq299o1li21bvqavnppmuqjt66vv95o.apps.googleusercontent.com">
                <BasicModal />
              </GoogleOAuthProvider>
            </PrivateLoginRoute>
          }
        />  */}
        <Route
          path={`/scholarship/upsc/document-form/:id`}
          element={<FinanceForm />}
        />
        <Route
          path={`/scholarship/upsc/document-form/:id/login`}
          element={
            <FinanceRoute>
              <BasicFinanceModal />
            </FinanceRoute>
          }
        />
        <Route
          path={`/finance/login`}
          element={
            <LoginCounsellorRoute>
              <CounsellorSignIn />
            </LoginCounsellorRoute>
          }
        />{" "}
        <Route
          path={`/finance/dashboard`}
          element={
            <PrivateCounsellorRoute>
              <CounsellorDashboard />
            </PrivateCounsellorRoute>
          }
        />
        <Route
          path={`/finance/dashboard/:id`}
          element={
            <PrivateCounsellorRoute>
              <FormDetails />
            </PrivateCounsellorRoute>
          }
        />
        <Route
          path={`/request-leads`}
          element={
            <GoogleOAuthProvider clientId="555163836458-ekq299o1li21bvqavnppmuqjt66vv95o.apps.googleusercontent.com">
              <PaymentPage />
            </GoogleOAuthProvider>
          }
        />
        <Route
          path={`/request-leads/login`}
          element={
            <RequestLeadsPrivateLoginRoute>
              <GoogleOAuthProvider clientId="555163836458-ekq299o1li21bvqavnppmuqjt66vv95o.apps.googleusercontent.com">
                <BasicLeadRequestModal />
              </GoogleOAuthProvider>
            </RequestLeadsPrivateLoginRoute>
          }
        />
      </Routes>
      {showModal && (
        <div className="showModalPrivacy">
          <div
            style={{
              display: "flex",
              width: "fit-content",
              height: "fit-content",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon
              onClick={handleAcceptTerms}
              style={{ cursor: "pointer" }}
            />
          </div>
          <p>
            We have updated our{" "}
            <a
              href="https://www.joshtalks.com/en/privacy-policy"
              alt="privacy-policy"
              target="_blank"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://www.joshtalks.com/en/terms-conditions"
              alt="terms-conditions"
              target="_blank"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Terms & Conditions
            </a>
            . By continuing to use our website, you acknowledge and agree to the
            revised terms.
          </p>
        </div>
      )}
    </div>
  );
}

export default App;
