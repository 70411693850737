import { createContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const SecondDataContext = createContext({
  loginSuccess: "",
  handleLoginSuccess: () => {},
  counsellorData: {},

  handleCounsellorData: () => {},
  formData: {},
  setFormData: () => {},
  handleFormData: () => {},
  handleApproved: () => {},
  handleReject: () => {},
  handleCloseChange: () => {},
});
export const SecondDataContextProvider = ({ children }) => {
  let [loginSuccess, setLoginSuccess] = useState(false);
  let [counsellorData, setCounsellorData] = useState(
    localStorage.getItem("CounsellorData")
      ? JSON.parse(localStorage.getItem("CounsellorData"))
      : null
  );

  let [formData, setFormData] = useState([]);

  let handleFormData = (data) => {
    setFormData(data);
  };

  let handleLoginSuccess = () => {
    setLoginSuccess(!loginSuccess);
  };
  let handleCounsellorData = (data) => {
    setCounsellorData(data);
  };

  let handleReject = (item) => {
    let data = formData;
    const updatedArray = data.map((newitem) => {
      console.log(newitem, " line 33222222", item);
      if (newitem.id == item.id) {
        return {
          ...newitem, // Copy all existing key-value pairs
          status: "REJECTED", // Modify the specific key with the desired new value
        };
      } else {
        return newitem;
      }
    });
    setFormData(updatedArray);
  };
  let handleApproved = (item) => {
    let data = formData;
    const updatedArray = data.map((newitem) => {
      if (newitem.id == item.id) {
        return {
          ...newitem, // Copy all existing key-value pairs
          status: "APPROVED", // Modify the specific key with the desired new value
        };
      } else {
        return newitem;
      }
    });
    setFormData(updatedArray);
  };
    let handleCloseChange = (item) => {
      let data = formData;
      const updatedArray = data.map((newitem) => {
        if (newitem.id == item.id) {
          return {
            ...newitem, // Copy all existing key-value pairs
            status: "CREATED", // Modify the specific key with the desired new value
          };
        } else {
          return newitem;
        }
      });
      setFormData(updatedArray);
    };
  return (
    <SecondDataContext.Provider
      value={{
        loginSuccess,
        handleLoginSuccess,
        counsellorData,
        handleCounsellorData,
        formData,
        setFormData,
        handleFormData,
        handleApproved,
        handleReject,
        handleCloseChange
      }}
    >
      {children}
    </SecondDataContext.Provider>
  );
};
